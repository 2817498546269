<template>
  <section class="container is-max-desktop">
    <b-tabs expanded>
      <b-tab-item label="アカウント" value="users">
        <form>
          <b-field label="メールアドレス">
            <b-input icon="email" :value="my.email" disabled></b-input>
          </b-field>
          <b-field label="権限">
            <b-select
              icon="account-question"
              :value="my.role"
              required
              expanded
              disabled
            >
              <option
                v-for="role in roles"
                :value="role.value"
                :key="role.value"
              >
                {{ role.label }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="新しいパスワード"
            :message="message('user.password')"
            :type="hasError('user.password')"
          >
            <b-input
              icon="form-textbox-password"
              type="password"
              v-model="form.user.password"
              autocomplete="new-password"
              password-reveal
            ></b-input>
          </b-field>
          <b-field
            label="新しいパスワード（確認用）"
            :message="message('user.password_confirm')"
            :type="hasError('user.password_confirm')"
          >
            <b-input
              icon="form-textbox-password"
              type="password"
              autocomplete="new-password"
              v-model="form.user.password_confirm"
              password-reveal
            ></b-input>
          </b-field>
          <b-field
            label="現在のパスワード"
            :message="message('user.old_password')"
            :type="hasError('user.old_password')"
          >
            <b-input
              icon="form-textbox-password"
              type="password"
              autocomplete="current-password"
              v-model="form.user.old_password"
              password-reveal
            ></b-input>
          </b-field>
          <div class="columns is-mobile">
            <div class="column" align="right">
              <b-button type="is-primary" @click="savePassword">保存</b-button>
            </div>
          </div>
        </form>
      </b-tab-item>
      <b-tab-item label="時間" value="time_settings">
        <b-field
          label="標準始業時間"
          :message="message('time_setting.work_hour_begin_at')"
          :type="hasError('time_setting.work_hour_begin_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.work_hour_begin_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="標準終業時間"
          :message="message('time_setting.work_hour_end_at')"
          :type="hasError('time_setting.work_hour_end_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.work_hour_end_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="昼休憩開始時間"
          :message="message('time_setting.break_time_begin_at')"
          :type="hasError('time_setting.break_time_begin_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.break_time_begin_at"
          >
          </b-timepicker>
        </b-field>
        <b-field
          label="昼休憩終了時間"
          :message="message('time_setting.break_time_end_at')"
          :type="hasError('time_setting.break_time_end_at')"
        >
          <b-timepicker
            icon="clock"
            editable
            hour-format="24"
            locale="ja-JP"
            v-model="form.time_setting.break_time_end_at"
          >
          </b-timepicker>
        </b-field>
        <div class="columns is-mobile">
          <div class="column" align="right">
            <b-button type="is-primary" @click="saveTimeSetting">保存</b-button>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="プロフィール" value="profiles">
        <b-field
          label="氏名"
          :type="hasError('profile.name')"
          :message="message('profile.name')"
        >
          <b-input
            icon="account"
            autocomplete="off"
            v-model="form.profile.name"
            required
            maxlength="256"
          ></b-input>
        </b-field>

        <b-field
          label="ふりがな"
          :type="hasError('profile.furigana')"
          :message="{
            'ひらがなで入力してください。': hasError('profile.furigana'),
          }"
        >
          <b-input
            icon="syllabary-hiragana"
            v-model="form.profile.furigana"
            required
            maxlength="256"
            pattern="^[ぁ-ん \u3000]+$"
          ></b-input>
        </b-field>

        <b-field
          label="社員番号"
          :type="hasError('profile.number')"
          :message="message('profile.number')"
        >
          <b-input
            icon="identifier"
            v-model="form.profile.number"
            maxlength="4"
            required
          ></b-input>
        </b-field>

        <b-field
          label="入社年月日"
          :type="hasError('profile.joined_on')"
          :message="message('profile.joined_on')"
        >
          <b-datepicker
            icon="office-building"
            editable
            locale="ja-JP"
            v-model="form.profile.joined_on"
            required
          ></b-datepicker>
        </b-field>

        <b-field label="職位・グレード">
          <b-input
            icon="medal"
            :value="form.profile.position + form.profile.grade"
            readonly
          ></b-input>
        </b-field>

        <b-field
          label="初回年休付与日"
          :type="hasError('profile.first_paid_annual_leave_granted_on')"
          :message="message('profile.first_paid_annual_leave_granted_on')"
        >
          <b-datepicker
            icon="calendar-star"
            editable
            locale="ja-JP"
            v-model="form.profile.first_paid_annual_leave_granted_on"
            required
          ></b-datepicker>
        </b-field>

        <b-field
          label="生年月日"
          :type="hasError('profile.date_of_birth')"
          :message="message('profile.date_of_birth')"
        >
          <b-datepicker
            icon="cake"
            editable
            locale="ja-JP"
            v-model="form.profile.date_of_birth"
            required
          ></b-datepicker>
        </b-field>

        <b-field
          label="性別"
          :type="hasError('profile.sex')"
          :message="message('profile.sex')"
        >
          <b-select icon="circle" v-model="form.profile.sex" expanded required>
            <option v-for="sex in sexes" :value="sex.value" :key="sex.value">
              {{ sex.label }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="婚姻状況"
          :type="hasError('profile.marital_status')"
          :message="message('profile.marital_status')"
        >
          <b-select
            icon="heart-circle"
            v-model="form.profile.marital_status"
            expanded
            required
          >
            <option
              v-for="maritalStatus in maritalStatuses"
              :value="maritalStatus.value"
              :key="maritalStatus.value"
            >
              {{ maritalStatus.label }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="住所（市区町村まで）"
          :type="hasError('profile.address')"
          :message="message('profile.address')"
        >
          <b-input icon="home" v-model="form.profile.address"></b-input>
        </b-field>
        <b-field
          label="最寄路線"
          :type="hasError('profile.nearest_line')"
          :message="message('profile.nearest_line')"
        >
          <b-input
            icon="train"
            v-model="form.profile.nearest_line"
            expanded
          ></b-input>
          <p class="control">
            <span class="button is-static">線</span>
          </p>
        </b-field>
        <b-field
          label="最寄駅"
          :type="hasError('profile.nearest_station')"
          :message="message('profile.nearest_station')"
        >
          <b-input
            icon="train"
            v-model="form.profile.nearest_station"
            expanded
          ></b-input>
          <p class="control">
            <span class="button is-static">駅</span>
          </p>
        </b-field>
        <b-field
          label="最終学歴"
          :type="hasError('profile.education')"
          :message="message('profile.education')"
        >
          <b-input icon="school" v-model="form.profile.education"></b-input>
        </b-field>
        <b-field label="アピールポイント">
          <b-input
            type="textarea"
            maxlength="800"
            v-model="form.profile.strength"
          ></b-input>
        </b-field>
        <b-field
          label="SlackメンバーID"
          :type="hasError('profile.slack_user_name')"
          :message="message('profile.slack_user_name')"
        >
          <b-input
            icon="slack"
            v-model="form.profile.slack_user_name"
          ></b-input>
        </b-field>
        <div class="columns is-mobile">
          <div class="column" align="right">
            <b-button type="is-primary" @click="saveProfile">保存</b-button>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="資格" value="certificates">
        <b-field
          v-for="(certificate, index) in form.certificates"
          :key="certificate.id"
          grouped
        >
          <b-field
            :type="certificate.$delete ? 'is-danger' : ''"
            :label="
              certificate.$delete
                ? '削除'
                : certificate.id === null
                ? '追加'
                : ''
            "
            expanded
          >
            <b-input
              :disabled="certificate.$delete"
              v-model="certificate.description"
              required
            ></b-input>
          </b-field>
          <b-button
            :type="
              certificate.$delete ? 'is-primary is-light' : 'is-danger is-light'
            "
            :icon-left="certificate.$delete ? 'undo' : 'minus'"
            @click="removeCertificate(index)"
          />
        </b-field>
        <b-button
          type="is-primary is-light"
          icon-left="plus"
          size="is-small"
          @click="addCertificate"
        />
        <div class="columns is-mobile">
          <div class="column" align="right">
            <b-button type="is-primary" @click="saveCertificates"
              >保存</b-button
            >
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { MARITAL_STATUS, ROLE, SEX } from "../constants";
import fetchMixin, { parseDateField, parseTimeField } from "../fetch-mixin";
import storeMixin, {
clone,
formatDateField,
formatTimeField,
} from "../store-mixin";

export default {
  name: "Menu",
  mixins: [fetchMixin, storeMixin],
  data() {
    return {
      form: {
        user: {
          password: null,
          password_confirm: null,
          old_password: null,
        },
        profile: {
          name: null,
          furigana: null,
          date_of_birth: null,
          sex: null,
          marital_status: null,
          address: "",
          nearest_line: "",
          nearest_station: "",
          education: "",
          number: null,
          joined_on: null,
          first_paid_annual_leave_granted_on: null,
          slack_user_name: null,
          strength: null,
        },
        time_setting: {
          work_hour_begin_at: null,
          work_hour_end_at: null,
          break_time_begin_at: null,
          break_time_end_at: null,
        },
        certificates: [],
      },
    };
  },
  computed: {
    my() {
      return this.$store.state.my.user;
    },
    roles() {
      return Object.values(ROLE);
    },
    sexes() {
      return Object.values(SEX);
    },
    maritalStatuses() {
      return Object.values(MARITAL_STATUS);
    },
  },
  methods: {
    initializeUserForm() {
      this.form.user = {
        password: null,
        password_confirm: null,
        old_password: null,
      };
    },
    initializeTimeSettingForm() {
      const myTimeSetting = this.$store.state.my.time_setting;
      this.form.time_setting = {
        work_hour_begin_at: parseTimeField(myTimeSetting.work_hour_begin_at),
        work_hour_end_at: parseTimeField(myTimeSetting.work_hour_end_at),
        break_time_begin_at: parseTimeField(myTimeSetting.break_time_begin_at),
        break_time_end_at: parseTimeField(myTimeSetting.break_time_end_at),
      };
    },
    initializeProfileForm() {
      const {
        date_of_birth,
        joined_on,
        first_paid_annual_leave_granted_on,
        ...rest
      } = this.$store.state.my.profile;
      this.form.profile = {
        ...rest,
        date_of_birth: parseDateField(date_of_birth),
        joined_on: parseDateField(joined_on),
        first_paid_annual_leave_granted_on: parseDateField(
          first_paid_annual_leave_granted_on
        ),
      };
    },
    initializeCertificatesForm() {
      const myCertificates = this.$store.state.my.profile.certificates;
      if (myCertificates === undefined || myCertificates.length === 0) {
        this.form.certificates = [
          {
            id: null,
            description: null,
            $delete: false,
          },
        ];
      } else {
        const certificates = myCertificates.map((v) => ({
          ...v,
          $delete: false,
        }));
        this.$set(this.form, "certificates", certificates);
      }
    },
    addCertificate() {
      this.form.certificates.push({
        id: null,
        description: null,
        $delete: false,
      });
    },
    removeCertificate(index) {
      const cert = this.form.certificates[index];
      if (cert.id === null) {
        this.form.certificates.splice(index, 1);
      } else {
        this.$set(cert, "$delete", !cert.$delete);
      }
    },
    async savePassword() {
      try {
        await this.store("my/updateMe", { user: this.form.user });
        this.initializeUserForm();
      } catch (e) {
        console.log(e);
      }
    },
    async saveTimeSetting() {
      try {
        const time_setting = clone(this.form.time_setting);
        time_setting.work_hour_begin_at = formatTimeField(
          time_setting.work_hour_begin_at
        );
        time_setting.work_hour_end_at = formatTimeField(
          time_setting.work_hour_end_at
        );
        time_setting.break_time_begin_at = formatTimeField(
          time_setting.break_time_begin_at
        );
        time_setting.break_time_end_at = formatTimeField(
          time_setting.break_time_end_at
        );

        await this.store("my/updateMe", { time_setting });
        this.initializeTimeSettingForm();
      } catch (e) {
        console.log(e);
      }
    },
    async saveProfile() {
      try {
        const profile = clone(this.form.profile);
        profile.date_of_birth = formatDateField(profile.date_of_birth);
        profile.joined_on = formatDateField(profile.joined_on);
        profile.first_paid_annual_leave_granted_on = formatDateField(
          profile.first_paid_annual_leave_granted_on
        );

        await this.store("my/updateProfile", profile);
        this.initializeProfileForm();
      } catch (e) {
        console.log(e);
      }
    },
    async saveCertificates() {
      const needAdd = (v) =>
        v.id === null &&
        v.description !== null &&
        v.description !== "" &&
        !v.$delete;

      const needUpdate = (v) =>
        v.id !== null &&
        v.description !== null &&
        v.description !== "" &&
        !v.$delete;

      const needRemove = (v) => v.id !== null && v.$delete;

      try {
        const certificates = this.form.certificates.filter(
          (v) => needAdd(v) || needUpdate(v) || needRemove(v)
        );

        const actions = certificates.map((certificate) => {
          if (needAdd(certificate)) {
            return this.store("my/addCertificate", certificate);
          } else if (needUpdate(certificate)) {
            return this.store("my/updateCertificate", certificate);
          } else {
            return this.store("my/removeCertificate", certificate.id);
          }
        });
        await Promise.all(actions);
        this.initializeCertificatesForm();
      } catch (e) {
        //
      }
    },
  },
  async mounted() {
    await this.fetch("my/getMe");
    await this.fetch("my/getProfile");
    this.initializeUserForm();
    this.initializeTimeSettingForm();
    this.initializeProfileForm();
    this.initializeCertificatesForm();
  },
};
</script>
